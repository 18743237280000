// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-detail-jsx": () => import("../src/templates/BlogDetail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-blog-jsx": () => import("../src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-page-jsx": () => import("../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-video-jsx": () => import("../src/templates/Video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-case-study-detail-jsx": () => import("../src/templates/CaseStudyDetail.jsx" /* webpackChunkName: "component---src-templates-case-study-detail-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-inform-changelog-jsx": () => import("../src/pages/inform-changelog.jsx" /* webpackChunkName: "component---src-pages-inform-changelog-jsx" */),
  "component---src-pages-latest-wordlx-download-jsx": () => import("../src/pages/latest-wordlx-download.jsx" /* webpackChunkName: "component---src-pages-latest-wordlx-download-jsx" */),
  "component---src-pages-resources-jsx": () => import("../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-wordlx-changelog-jsx": () => import("../src/pages/wordlx-changelog.jsx" /* webpackChunkName: "component---src-pages-wordlx-changelog-jsx" */)
}

